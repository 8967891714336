import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HotelPresentation } from '@api-clients/api-client';
import { AiVoiceConvertedFile } from '@api-clients/api-client/models';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, retry, Subject, throwError, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfigsService, WorkerStateService } from '../../../../../core/services';
import { ExternalLinkDirective } from '../../../../../shared/directives/external-link.directive';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../ui-components/alert-label/alert-label.component';

import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';
import { AiVoiceApiService } from '../../../services/ai-voice-api.service';
import { AudioPresentationPlayerComponent } from './components/audio-presentation-player.component';

@Component({
  selector: 'app-audio-presentation',
  templateUrl: './audio-presentation.component.html',
  styleUrls: ['./audio-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [
    AsyncPipe,
    TranslateModule,
    AlertLabelComponent,
    AudioPresentationPlayerComponent,
    ExternalLinkDirective,
  ],
})
export class AudioPresentationComponent implements OnInit, ContentCreatorItemComponent, OnDestroy {
  @Input() public tourId: string;
  @Input() public tourContent: TourContent;
  public allPresentations: HotelPresentation[];
  public currentEmployeePresentations: HotelPresentation[];
  public otherEmployeesPresentations: HotelPresentation[];
  public activePresentation: HotelPresentation;
  /**
   * Есть ли у текущего сотрудника голосовая модель
   */
  public currentEmployeeHasVoiceModel = false;
  public adminUrl: string;
  private presentationsAsText = new Map<HotelPresentation, string>();

  public errorMessage$ = new BehaviorSubject<string | null>(null);
  private destroy$ = new Subject<void>();

  protected readonly dangerAlertLabelType = AlertLabelType.danger;

  constructor(
    private configsService: ConfigsService,
    private aiVoiceApiService: AiVoiceApiService,
    private workerStateService: WorkerStateService,
    private translatePipe: TranslatePipe,
  ) {
    this.adminUrl = this.configsService.adminUrl;
  }

  ngOnInit(): void {
    this.allPresentations = this.tourContent?.hotel?.audioPresentations;
    this.currentEmployeeHasVoiceModel = this.workerStateService.currentWorkerValue?.hasVoiceModel;
    if (!this.allPresentations) {
      this.errorMessage$.next(
        this.translatePipe.transform('PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.NOT_FOUND'),
      );
    } else {
      // Нам надо отсортировать презентации. Порядок такой:
      // 1. Эталонные презентации текущего сотрудника
      // 2. Презентации текущего сотрудника
      // 3. Эталонные презентации других сотрудников
      // 4. Презентации других сотрудников
      // Эталонное поле isStandard
      this.allPresentations = this.allPresentations.sort((a, b) => {
        const isMyPresentationA = a.employeeId === this.workerStateService.employeeId;
        const isMyPresentationB = b.employeeId === this.workerStateService.employeeId;
        if (isMyPresentationA && !isMyPresentationB) {
          return -1;
        }
        if (!isMyPresentationA && isMyPresentationB) {
          return 1;
        }

        if (a.isStandard && !b.isStandard) {
          return -1;
        }
        if (!a.isStandard && b.isStandard) {
          return 1;
        }
        return 0;
      });
      this.currentEmployeePresentations = [];
      this.otherEmployeesPresentations = [];
      const withChildren = this.tourContent.tourMessageData.children;
      const addPresentation = (presentationList: HotelPresentation[], presentation: HotelPresentation) => {
        // Если в туре были дети, то показываем только презентации с детьми
        if (withChildren && presentation.isWithChildren) {
          presentationList.push(presentation);
        }
        // Если в туре не было детей, то показываем презентации без детей
        else if (!withChildren && !presentation.isWithChildren) {
          presentationList.push(presentation);
        }
      };

      this.allPresentations.forEach(presentation => {
        // Оставляем презентации текущего сотрудника или эталонные презентации
        if (presentation.employeeId === this.workerStateService.employeeId) {
          addPresentation(this.currentEmployeePresentations, presentation);
        } else {
          // Если презентация не текущего сотрудника, то показываем только эталонные презентации
          if (presentation.isStandard) {
            addPresentation(this.otherEmployeesPresentations, presentation);
          }
        }
      });
      if (!this.currentEmployeePresentations.length && !this.otherEmployeesPresentations.length) {
        this.errorMessage$.next(
          this.translatePipe.transform('PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.NOT_FOUND'),
        );
      }
      if (this.currentEmployeePresentations.length) {
        this.activePresentation = this.currentEmployeePresentations[0];
      } else if (this.otherEmployeesPresentations.length) {
        this.activePresentation = this.otherEmployeesPresentations[0];
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setActivePresentation(presentation: HotelPresentation): void {
    this.activePresentation = presentation;
  }

  setSendAsText(
    presentation: HotelPresentation,
    data: { isChecked: boolean; transcriptionResult: string },
  ): void {
    if (data.isChecked) {
      this.presentationsAsText.set(presentation, data.transcriptionResult);
    } else {
      this.presentationsAsText.delete(presentation);
    }
  }

  /**
   * Меняет голос в аудио-презентации на голос текущего сотрудника
   */
  private convertAudio(): Observable<AiVoiceConvertedFile> {
    // Если первый запрос не вернет файл с заполненным s3Url, то значит файл в процессе конвертации.
    // Будем повторять запрос каждые 5 секунд, пока не получим файл
    const retryDelay = 5000;
    // Повторять запрос максимум 120 раз (10 минут)
    const retryCount = 120;

    return this.aiVoiceApiService
      .convert(this.activePresentation.id, this.workerStateService.employeeId)
      .pipe(
        switchMap(convertedFile => {
          if (convertedFile.state === 'error') {
            throw new Error('Api error while converting audio');
          }
          if (convertedFile.s3Url) {
            return of(convertedFile);
          } else {
            // Если файл не готов, выбрасываем ошибку для повторного запроса
            return throwError(() => new Error('File not ready'));
          }
        }),
        retry({
          delay: (error: Error) => {
            // Повторять только в случае ошибки 'File not ready'
            if (error.message === 'File not ready') {
              return timer(retryDelay);
            } else {
              // Прерываем повторы в случае других ошибок
              throw error;
            }
          },
          count: retryCount,
        }),
      );
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    if (!this.activePresentation) {
      return of([] as ContentCreatorItemMessage[]);
    }

    if (this.presentationsAsText.has(this.activePresentation)) {
      return of([
        {
          component: this,
          message: { text: this.presentationsAsText.get(this.activePresentation) },
        },
      ]);
    }

    // Если презентация не от текущего сотрудника, то надо конвертировать аудио его голосом
    if (this.activePresentation.employeeId !== this.workerStateService.employeeId) {
      return this.convertAudio().pipe(
        switchMap(convertedFile => {
          return of([
            {
              component: this,
              message: { fileUrl: convertedFile.s3Url },
            },
          ]);
        }),
        catchError(() => {
          alert(`Не удалось конвертировать аудио-презентацию. Обратитесь в техподдержку`);
          return of([]);
        }),
      );
    }

    // Если презентация от текущего сотрудника, то отправляем ее как есть
    return of([
      {
        component: this,
        message: {
          // По умолчанию отправляется обработанный и ускоренный файл
          fileUrl: this.activePresentation.postProcessedFileUrl || this.activePresentation.fileUrl,
        },
      },
    ]);
  }
}
