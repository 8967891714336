import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-zenmode-welcome-tips',
  templateUrl: './zenmode-welcome-tips.component.html',
  styleUrls: ['./zenmode-welcome-tips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [FormsModule, TranslateModule],
})
export class ZenmodeWelcomeTipsComponent {
  @Output() startEvent = new EventEmitter<boolean>();
  isDontShowAgain = false;
  tipKeys = ['notify', 'calls', 'exit', 'auto', 'switch', 'retain'];
  emitEvent() {
    this.startEvent.emit(this.isDontShowAgain);
  }
}
