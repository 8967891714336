<div
  class="status-selector"
  [matMenuTriggerFor]="statusMenu"
  (click)="toggleDropdown()"
  (menuClosed)="isOpen.set(false)"
>
  <app-status-indicator [status]="currentManagerSipState()?.state" />
  <img src="./assets/icons/top-menu/{{ isOpen() ? 'arrow-up' : 'arrow-down' }}.svg" />
</div>

<mat-menu #statusMenu="matMenu" xPosition="before">
  <button mat-menu-item (click)="onManagerStatusChange(true)">
    <app-status-indicator [status]="managerSipStateEnum.online" />
  </button>
  <button mat-menu-item (click)="onManagerStatusChange(false)">
    <app-status-indicator [status]="managerSipStateEnum.manual_busy" />
  </button>
</mat-menu>
