import { AsyncPipe, NgClass } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { ZenmodePaginateComponent } from '../../../zenmode/modules/zenmode-paginate/components/zenmode-paginate/zenmode-paginate.component';
import { AsideMenuComponent } from '../aside-menu/aside-menu.component';
import { TopMenuComponent } from '../top-menu/top-menu.component';

const DEAL_LIST_PAGE_URL = '/deals';
const MAIN_PAGE_URL = '/';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TopMenuComponent, NgClass, AsyncPipe, AsideMenuComponent, RouterOutlet, ZenmodePaginateComponent],
})
export class MainComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  public hideReturnToCall = true;
  public isZenModeOn$ = this.dealsFacade.zenmodeOn$;

  constructor(
    private router: Router,
    private dealsFacade: DealsFacade,
  ) {
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        this.hideReturnToCall = event.url.includes(DEAL_LIST_PAGE_URL) || event.url.includes(MAIN_PAGE_URL);
      }
    });
  }

  ngOnInit(): void {}
}
