import { Injectable } from '@angular/core';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SearchResultGroup } from '../../deals/modules/deal-view/modules/search-tours/search-tours.model';
import { InitSearchRequest } from './search/websocket-tours.model';

export interface ConfigureSearchResultsComponent {
  initSearchRequest: InitSearchRequest;
  crmCard: CrmCardViewItem;
  resultGroups: SearchResultGroup[];
  county: SearchFormParamsCountry;
}

@Injectable({ providedIn: 'root' })
export class SearchResultService {
  private showResultComponentSub = new BehaviorSubject<boolean>(false);
  private searchResultGroupsSub = new BehaviorSubject<SearchResultGroup[]>([]);
  private showHotelOnMapSub = new Subject<number>();
  private activeCrmCardSub = new BehaviorSubject<CrmCardViewItem>(undefined);
  private configureMapSub = new Subject<SearchResultGroup[]>();
  private currentInitSearchRequestSub = new Subject<InitSearchRequest>();
  private currentCountrySub = new Subject<SearchFormParamsCountry>();

  get currentCountry$(): Observable<SearchFormParamsCountry> {
    return this.currentCountrySub.asObservable();
  }

  get currentInitSearchRequest$(): Observable<InitSearchRequest> {
    return this.currentInitSearchRequestSub.asObservable();
  }

  configureSearchResultComponent(config: ConfigureSearchResultsComponent): void {
    this.activeCrmCardSub.next(config.crmCard);
    this.configureMapSub.next(config.resultGroups);
    this.currentInitSearchRequestSub.next(config.initSearchRequest);
    this.currentCountrySub.next(config.county);
  }

  get configureMap$(): Observable<SearchResultGroup[]> {
    return this.configureMapSub.asObservable();
  }

  get showResultComponent$(): Observable<boolean> {
    return this.showResultComponentSub.asObservable();
  }

  get activeCrmCard$(): Observable<CrmCardViewItem> {
    return this.activeCrmCardSub.asObservable();
  }

  showResultComponent(): void {
    this.showResultComponentSub.next(true);
  }

  hideResultComponent(): void {
    this.showResultComponentSub.next(false);
  }

  getSearchResultGroups$(): Observable<SearchResultGroup[]> {
    return this.searchResultGroupsSub.asObservable();
  }

  setSearchResultGroups(value: SearchResultGroup[]) {
    this.searchResultGroupsSub.next(value);
  }

  get showHotelOnMap$(): Observable<number> {
    return this.showHotelOnMapSub.asObservable();
  }

  showHotelOnMap(hotelId: number): void {
    this.showHotelOnMapSub.next(hotelId);
  }
}
