import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TourPackageClaimNotification } from '@api-clients/crm-api-client/dist/models';
import { TranslateModule } from '@ngx-translate/core';
import { ExternalLinkDirective } from '../../../../../../shared/directives/external-link.directive';

@Component({
  selector: 'app-tour-package-claim-outdated',
  templateUrl: './tour-package-claim-outdated.component.html',
  styleUrls: ['./tour-package-claim-outdated.component.scss'],

  imports: [TranslateModule, DatePipe, ExternalLinkDirective],
})
export class TourPackageClaimOutdatedComponent implements OnInit {
  @Input() notification: TourPackageClaimNotification;
  dateFormat = 'dd.MM.yyyy';

  ngOnInit(): void {}
}
