import { ChangeDetectionStrategy, Component, inject, signal, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { ManagerStateFacade } from '../../../../+state/manager-state/manager-state.facade';
import {
  ManagerSipStateEnum,
  ManagerSipStateInterface,
} from '../../../../+state/manager-state/manager-state.state';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';

@Component({
  selector: 'app-worker-status',
  templateUrl: './worker-status.component.html',
  styleUrls: ['./worker-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenu, MatMenuItem, StatusIndicatorComponent, MatMenuTrigger],
})
export class WorkerStatusComponent {
  private readonly managerStateFacade = inject(ManagerStateFacade);

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public currentManagerSipState = toSignal(this.managerStateFacade.managerSipState$);
  public isOpen = signal(false);
  public managerSipStateEnum = ManagerSipStateEnum;

  onManagerStatusChange(status: boolean): void {
    this.toggleDropdown();
    const managerState: ManagerSipStateInterface = {
      state: status ? ManagerSipStateEnum.online : ManagerSipStateEnum.manual_busy,
      value: status,
    };
    this.managerStateFacade.setManagerSipState(managerState);
  }

  toggleDropdown() {
    this.isOpen.set(!this.isOpen());
    if (this.isOpen()) {
      this.trigger.openMenu();
    } else {
      this.trigger.closeMenu();
    }
  }
}
