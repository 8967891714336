import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DealChangedEventDetail } from '@api-clients/crm-api-client';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, combineLatest, concat, from, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { WorkerStateService } from '../../core/services';
import { AmplitudeTrackService } from '../../core/services/amplitude/amplitude-track.service';
import {
  DEALS_LIST_SORTING_TYPE_SET,
  ZENMODE_CARD_MISSED,
  ZENMODE_MANUAL_TUTORIAL_LAUNCHED,
  ZENMODE_NEXT_CARD_PRESSED,
  ZENMODE_TOGGLE,
} from '../../core/services/amplitude/amplitudeEvents';
import { MarkAsNotNewService } from '../../modules/deals/modules/deal-view/services/mark-as-not-new/mark-as-not-new.service';
import { FeatureToggleService } from '../../modules/deals/modules/deals-list/modules/deals-list-content/helpers/feature-toggle.service';
import { DealListSortService } from '../../modules/deals/modules/deals-list/services/deal-list-sort.service';
import { DealListService } from '../../modules/deals/modules/deals-list/services/deal-list.service';
import { FeatureId } from '../../shared/services/feature-id.enum';
import { IntroManagementService } from '../../shared/services/intro-management.service';
import {
  AddToZenmodeList,
  AddViewedCard,
  CombinedDealsLoaded,
  DealChanged,
  DealChangedSuccess,
  DealSelectedByZenmodeCard,
  DealsActionTypes,
  DealsChangeSortType,
  DealsLoaded,
  DealsLoadedError,
  DealsPostSaleLoaded,
  DealsSelectNextCard,
  DealsSelectPreviousCard,
  MarkDealAsNotNew,
  NavigateNextCard,
  NavigatePreviousCard,
  NewMessageReceived,
  RemoveShadowTime,
  RemoveSkippedCard,
  RemoveViewedCard,
  SortDeals,
  SortDealsSuccess,
  ToggleZenmode,
} from './deals.actions';
import { DealsFacade } from './deals.facade';
import { SelectedZenmodeCard, ShowZenModeIntroAction } from './deals.interface';

@Injectable()
export class DealsEffects {
  loadDeals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.LoadDeals),
      switchMap(() =>
        this.dealList.getDealList().pipe(
          map(data => new DealsLoaded(data.list)),
          catchError(error => of(new DealsLoadedError({ error }))),
        ),
      ),
    ),
  );

  dealsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.DealsLoaded),
      map(() => new SortDeals()),
    ),
  );

  updateLastWhatsappMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.UpdateLastWhatsappMessage),
      map(() => new SortDeals()),
    ),
  );

  changeSortType$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DealsChangeSortType>(DealsActionTypes.DealsChangeSortType),
      tap(action => {
        this.amplitudeTrackService.trackEvent(DEALS_LIST_SORTING_TYPE_SET, {
          'Sort type': action.payload,
        });
      }),
      map(() => new SortDeals()),
    ),
  );

  sortDeals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.SortDeals),
      withLatestFrom(
        this.dealsFacade.dealsList$,
        this.dealsFacade.sortType$,
        this.workerStateService.currentWorker$,
        this.featureToggleService.shouldDisplayNewFeature$,
      ),
      filter(([, , , worker, isDealListNewUiEnabled]) => {
        return !!worker && isDealListNewUiEnabled;
      }),
      map(([, deals, sortType]) => {
        const sortedDeals = this.dealListSortService.sortDeals(deals, sortType);
        return new SortDealsSuccess(sortedDeals);
      }),
    ),
  );

  loadDealsPostSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.LoadDeals),
      switchMap(() =>
        this.dealList.getDealListPostSale().pipe(
          map(data => new DealsPostSaleLoaded(data.list)),
          catchError(error => of(new DealsLoadedError({ error }))),
        ),
      ),
    ),
  );

  loadCombinedDeals$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(
        ofType<DealsLoaded>(DealsActionTypes.DealsLoaded),
        map(action => action.payload),
      ),
      this.actions$.pipe(
        ofType<DealsPostSaleLoaded>(DealsActionTypes.DealsPostSaleLoaded),
        map(action => action.payload),
      ),
      this.dealsFacade.zenmodeOn$,
    ]).pipe(
      filter(([, , zenmodeOn]) => zenmodeOn),
      map(([deals, postSaleDeals]) => new CombinedDealsLoaded({ deals, postSaleDeals })),
    ),
  );

  handleDealsSelectNextCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.DealsSelectNextCard),
      withLatestFrom(this.dealsFacade.selectedZenmodeCard$, this.dealsFacade.zenmodeOn$),
      filter(([, , zenmodeOn]) => zenmodeOn),
      map(([, selectedZenmodeCard]) => {
        const dealChangedPayload: DealChangedEventDetail = {
          dealId: selectedZenmodeCard.dealId,
          conversationStatus: 0,
          isNew: false,
        };
        return new DealChanged(dealChangedPayload);
      }),
    ),
  );

  dealChangedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.DealChanged),
      map((action: DealChanged) => new DealChangedSuccess({ dealId: action.payload.dealId })),
    ),
  );

  showZenmodeIntro$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ShowZenModeIntroAction>(DealsActionTypes.ShowZenModeIntro),
        tap(action => this.handleShowZenModeIntro(action)),
      ),
    { dispatch: false },
  );

  toggleZenMode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.ToggleZenmode),
      withLatestFrom(this.dealsFacade.zenmodeList$),
      switchMap(([action, zenmodeList]: [ToggleZenmode, CrmCardViewItem[]]) => {
        this.amplitudeTrackService.trackEvent(ZENMODE_TOGGLE, { value: action.payload.toString() });

        if (zenmodeList.length > 0 && action.payload) {
          const selectedCard: SelectedZenmodeCard = {
            dealId: zenmodeList[0].deal.id,
            cardId: zenmodeList[0].card.id,
            isTouched: false,
            status: zenmodeList[0].deal.status,
            name: zenmodeList[0].card.name,
          };

          this.router.navigate(['/deals/view', selectedCard.cardId]);
          const addViewedCard$ = of(new AddViewedCard(selectedCard));
          const markDealAsNotNew$ = of(new MarkDealAsNotNew({ dealId: selectedCard.dealId }));
          const dealSelectedByZenModeCard$ = of(new DealSelectedByZenmodeCard(selectedCard));
          return concat(addViewedCard$, dealSelectedByZenModeCard$, markDealAsNotNew$);
        } else {
          return of({ type: '[Deals] No Operation' });
        }
      }),
    ),
  );

  selectNextCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DealsSelectNextCard>(DealsActionTypes.DealsSelectNextCard),
      withLatestFrom(this.dealsFacade.selectedZenmodeCard$),
      map(([action]) => {
        const { isSkip } = action.payload;

        if (isSkip) {
          this.amplitudeTrackService.trackEvent(ZENMODE_CARD_MISSED);
        } else {
          this.amplitudeTrackService.trackEvent(ZENMODE_NEXT_CARD_PRESSED);
        }

        return new NavigateNextCard();
      }),
    ),
  );

  selectPreviousCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DealsSelectPreviousCard>(DealsActionTypes.DealsSelectPreviousCard),
      map(() => new NavigatePreviousCard()),
    ),
  );

  navigateNextCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<NavigateNextCard>(DealsActionTypes.NavigateNextCard),
      withLatestFrom(this.dealsFacade.selectedZenmodeCard$),
      filter(([, selectedCard]) => !!selectedCard),
      exhaustMap(([, selectedCard]) => {
        this.router.navigate(['/deals/view', selectedCard.cardId]);
        return [new AddViewedCard(selectedCard), new MarkDealAsNotNew({ dealId: selectedCard.dealId })];
      }),
    ),
  );

  navigatePreviousCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<NavigatePreviousCard>(DealsActionTypes.NavigatePreviousCard),
      withLatestFrom(this.dealsFacade.selectedZenmodeCard$),
      filter(([, selectedCard]) => !!selectedCard),
      exhaustMap(([, selectedCard]) => {
        this.router.navigate(['/deals/view', selectedCard.cardId]);
        return [new RemoveSkippedCard(selectedCard)];
      }),
    ),
  );

  markDealAsNotNew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.MarkDealAsNotNew),
      delay(5000), // Добавляем задержку чтобы успеть загрузить карточку
      switchMap((action: MarkDealAsNotNew) =>
        this.markAsNotNewService.markAsNotNew({ dealId: action.payload.dealId }).pipe(
          map(() => ({
            type: DealsActionTypes.MarkedAsNotNew,
            payload: action.payload,
          })),
          catchError(error => this.handleError(error)),
        ),
      ),
    ),
  );

  updateUntouchedCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DealsActionTypes.CombinedDealsLoaded,
        DealsActionTypes.AddViewedCard,
        DealsActionTypes.AddSkippedCard,
        DealsActionTypes.RemoveSkippedCard,
        DealsActionTypes.RemoveViewedCard,
        DealsActionTypes.RemoveShadowTime,
        DealsActionTypes.AddToZenmodeList,
      ),
      debounceTime(300),
      withLatestFrom(
        this.dealsFacade.zenmodeOn$,
        this.dealsFacade.viewedCards$,
        this.dealsFacade.skippedCards$,
        this.dealsFacade.backtrackedCards$,
        this.dealsFacade.getShadowNextTasksMap$,
        this.dealsFacade.zenmodeList$,
      ),
      filter(([_, zenmodeOn]) => zenmodeOn),
      map(() => {
        return { type: DealsActionTypes.UpdateUntouchedCards };
      }),
    ),
  );

  updateCardsAfterCombinedDealsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.CombinedDealsLoaded),
      withLatestFrom(this.dealsFacade.zenmodeOn$, this.dealsFacade.zenmodeList$),
      filter(([, zenmodeOn]) => zenmodeOn),
      switchMap(([, , zenmodeList]) => {
        return zenmodeList.reduce((actions, card) => {
          if (card.deal.conversationStatus === 1) {
            const payload = { cardId: card.card.id };
            actions.push(
              new RemoveSkippedCard(payload),
              new RemoveViewedCard(payload),
              new AddToZenmodeList(payload),
              new RemoveShadowTime(payload),
            );
          }
          return actions;
        }, []);
      }),
    ),
  );

  newMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealsActionTypes.NewMessageReceived),
      map((action: NewMessageReceived) => action.payload),
      withLatestFrom(this.dealsFacade.zenmodeOn$),
      filter(([, zenmodeOn]) => zenmodeOn),
      switchMap(([payload]) => {
        const actions = [
          new RemoveSkippedCard(payload),
          new RemoveViewedCard(payload),
          new AddToZenmodeList(payload),
          new RemoveShadowTime(payload),
        ];
        return from(actions);
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private dealList: DealListService,
    private dealsFacade: DealsFacade,
    private introManagementService: IntroManagementService,
    private router: Router,
    private amplitudeTrackService: AmplitudeTrackService,
    private markAsNotNewService: MarkAsNotNewService,
    private dealListSortService: DealListSortService,
    private workerStateService: WorkerStateService,
    private featureToggleService: FeatureToggleService,
  ) {}

  private handleShowZenModeIntro(action: ShowZenModeIntroAction) {
    const { isManual } = action.payload;
    this.introManagementService.queueIntro(
      FeatureId.ZENMODE,
      isManual,
      ['TOGGLE', 'END_TASK', 'PAGINATE', 'HELP'],
      null,
    );
    if (isManual) {
      this.amplitudeTrackService.trackEvent(ZENMODE_MANUAL_TUTORIAL_LAUNCHED);
    }
  }

  private handleError(error: any) {
    console.error('Error in markDealAsNotNew:', error);
    return of({ type: '[Deals] Mark Deal As Not New Failure', payload: error });
  }
}
