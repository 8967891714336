import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  pure: true,
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, searchQuery: string): string {
    if (!searchQuery) {
      return value;
    }

    let result = '';
    let startIndex = 0;
    let index = value.indexOf(searchQuery, startIndex);

    while (index !== -1) {
      // Добавляем текст до совпадения
      result += value.slice(startIndex, index);
      // Добавляем выделенное совпадение
      result += `<mark>${value.slice(index, index + searchQuery.length)}</mark>`;
      // Сдвигаемся дальше найденного вхождения
      startIndex = index + searchQuery.length;

      // Ищем следующее вхождение
      index = value.indexOf(searchQuery, startIndex);
    }

    // Добавляем оставшуюся часть строки
    result += value.slice(startIndex);

    return result;
  }
}
