import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { Router } from '@angular/router';
import { CrmCardViewItem } from '@api-clients/crm-api-client';
import { ChatsFacade } from '../../../../../+state/chats/chats.facade';
import { getCardDefaultPhoneAsString } from '../../../../../helpers/crm-card';
import { CloseModalButtonComponent } from '../../../../../ui-components/close-modal-button/close-modal-button.component';

@Component({
  selector: 'app-calling-island',
  templateUrl: './calling-island.component.html',
  styleUrl: './calling-island.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgOptimizedImage, CloseModalButtonComponent],
})
export class CallingIslandComponent {
  private readonly chatsFacade = inject(ChatsFacade);
  private readonly router = inject(Router);

  crmCardViewItem = input<CrmCardViewItem>();

  close = output<void>();

  openChat(): void {
    if (!this.crmCardViewItem()) {
      return;
    }

    this.chatsFacade.setCrmCardViewItemForChat(this.crmCardViewItem());
    const phoneOrContactChatId = getCardDefaultPhoneAsString(this.crmCardViewItem().card);
    void this.router.navigate(['/chats', phoneOrContactChatId]).then(() => {
      this.chatsFacade.resetCurrentChatContact();
    });
  }
}
