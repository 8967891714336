<div class="popup">
  <div class="header">
    <h1>{{ 'PAGES.NOTIFICATIONS_CENTER.TITLE' | translate }}</h1>
    <a (click)="clearNotificationsCenter()">{{ 'PAGES.NOTIFICATIONS_CENTER.CLEAR_ALL' | translate }}</a>
  </div>
  <div class="notify-list-body">
    @if (listActual$ | async; as list) {
      <div class="notify-list">
        @if (list.list.length) {
          @for (item of list.list; track item) {
            @if (item.isNew) {
              <app-notify-center-item [notification]="item" (deletedById)="deleteById($event, item.type)">
              </app-notify-center-item>
            }
          }
        } @else {
          <div class="empty-list">
            {{ 'PAGES.NOTIFICATIONS_CENTER.EMPTY_LIST' | translate }}
          </div>
        }
      </div>
    }
    @if (listActual$ | async; as list) {
      @if (list.list.length) {
        <div class="header">
          <h2>{{ 'PAGES.NOTIFICATIONS_CENTER.TITLE_PREVIOUS' | translate }}</h2>
        </div>
        <div class="notify-list previous">
          @for (item of list.list; track item) {
            @if (!item.isNew) {
              <app-notify-center-item [notification]="item" (deletedById)="deleteById($event, item.type)">
              </app-notify-center-item>
            }
          }
        </div>
      }
    }
  </div>
</div>
