<div class="island" [class.card-available]="!!crmCardViewItem()">
  <div class="island-icon-wrapper">
    <img class="icon-search" ngSrc="assets/icons/telephone-inbound.svg" width="14" height="14" />
  </div>

  <div class="island-label" (click)="openChat()">
    @if (crmCardViewItem() && crmCardViewItem().card) {
      {{ crmCardViewItem().card.name }}
    } @else {
      Входящий звонок
    }
  </div>

  <div class="island-icon-close">
    <app-close-modal-button [isAbsolute]="false" (onCloseModal)="close.emit()" />
  </div>
</div>
