import { WorkerGetConfigResult } from '@api-clients/api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoadedSettings, LoadSettingsError, SettingsActionTypes } from './settings.actions';
import { SettingsApiService } from '../../core/services/settings/settings-api.service';

@Injectable()
export class SettingsEffects {
  loadConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActionTypes.LoadSettings),
      switchMap(() =>
        this.settingsApiService.getSettings().pipe(
          map((data: WorkerGetConfigResult) => new LoadedSettings(data)),
          catchError(error => of(new LoadSettingsError({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private settingsApiService: SettingsApiService,
  ) {}
}
