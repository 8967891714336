import { CrmCardViewAdditionItems, CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { CrmCardListPostSaleItem } from '@api-clients/crm-api-client/models/crm-card-list-post-sale-item';
import { CrmTaskItem, WhatsappMessage } from '@api-clients/crm-api-client';
import { addHours } from 'date-fns';
import { CrmCardListItem } from '@api-clients/crm-api-client/models/crm-card-list-item';
import { SortType } from '../../modules/deals/modules/deals-list/deal-list';
import { DealsAction, DealsActionTypes } from './deals.actions';
import { DealsState, SelectedZenmodeCard } from './deals.interface';
import { ZenmodeOrderService } from './services/zenmode-order-service.service';
import { AsideChat } from '../../modules/aside-chats/components/aside-chats';

export const dealsInitialState: DealsState = {
  list: [],
  listPostSale: [],
  asideChatList: [],
  sortType: SortType.LAST_TOUCH,
  loaded: false,
  loadedPostSale: false,
  postSaleDropdownIsOpen: false,
  selectedByZenmodeCard: null,
  isZenmodeOn: false,
  shadowNextTasksMap: {},
  viewedCards: [],
  skippedCards: [],
  backtrackedCards: [],
  zenmodeHistory: [],
  untouchedCards: [],
};
const zenmodeOrderPipe = new ZenmodeOrderService();
export const mockAdditionItems: CrmCardViewAdditionItems = {
  tasks: [],
  lastTasks: [],
  lastSmsList: [],
  tourPackages: [],
  calls: [],
  searchRequests: [],
};

export const convertToCrmCardViewItem = (dealListItem: CrmCardListItem): CrmCardViewItem => {
  return { ...dealListItem, ...mockAdditionItems };
};

export function dealsReducer(state: DealsState = dealsInitialState, action: DealsAction): DealsState {
  switch (action.type) {
    case DealsActionTypes.DealsLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true,
        asideChatList: getAsideChatList(action.payload),
      };
      break;
    }

    case DealsActionTypes.DealsPostSaleLoaded: {
      const uniquePostSaleItems = mapTourPackagesToUniqueItems(action.payload);
      sortTourPackagesByDepartureDate(uniquePostSaleItems);

      const newAsideChatItems = mapPostSaleItemsToAsideChat(uniquePostSaleItems);
      const asideChatListMap = new Map(state.asideChatList.map(item => [item.crmId, item]));

      newAsideChatItems.forEach(item => {
        asideChatListMap.set(item.crmId, item);
      });

      const newAsideChatList = Array.from(asideChatListMap.values());

      return {
        ...state,
        listPostSale: uniquePostSaleItems,
        loadedPostSale: true,
        asideChatList: newAsideChatList,
      };
    }

    case DealsActionTypes.SortDealsSuccess: {
      state = {
        ...state,
        list: action.payload,
      };
      break;
    }

    case DealsActionTypes.DealsChangeSortType: {
      state = {
        ...state,
        sortType: action.payload,
      };
      break;
    }

    case DealsActionTypes.DealsPostSaleDropdownOpen: {
      state = {
        ...state,
        postSaleDropdownIsOpen: true,
      };
      break;
    }

    case DealsActionTypes.DealsPostSaleDropdownClose: {
      state = {
        ...state,
        postSaleDropdownIsOpen: false,
      };
      break;
    }

    case DealsActionTypes.DealsLoadError:
      state = {
        ...state,
        loaded: false,
        error: action.payload,
      };
      break;
    case DealsActionTypes.DealChanged: {
      const newAsideChatList = updateChatItems(state.asideChatList, action.payload);
      return {
        ...state,
        asideChatList: newAsideChatList,
      };
    }

    case DealsActionTypes.DealsSelectNextCard: {
      if (!state.zenmodeList || state.zenmodeList.length === 0) {
        return state;
      }

      let nextCard: SelectedZenmodeCard | undefined;
      const newViewedCards = [...state.viewedCards];
      const newZenmodeHistory = [...state.zenmodeHistory];
      const newBacktrackedCards = [...state.backtrackedCards];

      if (newBacktrackedCards.length > 0) {
        nextCard = newBacktrackedCards.pop();
      } else {
        const newUntouchedCards = [...state.untouchedCards];

        if (newUntouchedCards.length > 0) {
          if (
            state.selectedByZenmodeCard &&
            newUntouchedCards[0].card.id === state.selectedByZenmodeCard.cardId
          ) {
            newUntouchedCards.shift();
          }

          if (newUntouchedCards.length > 0) {
            const nextUntouchedCard = newUntouchedCards[0];
            nextCard = {
              cardId: nextUntouchedCard.card.id,
              dealId: nextUntouchedCard.deal.id,
              isTouched: false,
              status: nextUntouchedCard.deal.status,
              name: nextUntouchedCard.card.name,
            };

            newViewedCards.push(nextCard);
            newZenmodeHistory.push(nextCard);
          }
        }
      }

      if (!nextCard) {
        break;
      }

      state = {
        ...state,
        viewedCards: newViewedCards,
        backtrackedCards: newBacktrackedCards,
        selectedByZenmodeCard: nextCard,
        zenmodeHistory: newZenmodeHistory,
      };
      break;
    }

    case DealsActionTypes.DealsSelectPreviousCard: {
      const currentIndexInHistory = state.zenmodeHistory.findIndex(
        card => card.cardId === state.selectedByZenmodeCard.cardId,
      );

      let prevCard;
      if (currentIndexInHistory > 0) {
        // Если selectedByZenmodeCard находится в середине истории
        prevCard = state.zenmodeHistory[currentIndexInHistory - 1];
      } else if (
        state.zenmodeHistory.length > 0 &&
        state.zenmodeHistory.length <= state.backtrackedCards.length
      ) {
        // Если selectedByZenmodeCard - первый или его нет, выбираем последний из zenmodeHistory
        prevCard = state.zenmodeHistory[state.zenmodeHistory.length - 1];
      } else {
        break;
      }

      const updatedSelectedByZenmodeCard: SelectedZenmodeCard = { ...prevCard };

      const newViewedCards = state.viewedCards.filter(
        card => card.cardId !== state.selectedByZenmodeCard.cardId,
      );

      const newBacktrackedCards = [...state.backtrackedCards, state.selectedByZenmodeCard];

      const newZenmodeHistory = state.zenmodeHistory.slice(0, currentIndexInHistory);

      state = {
        ...state,
        viewedCards: newViewedCards,
        backtrackedCards: newBacktrackedCards,
        selectedByZenmodeCard: updatedSelectedByZenmodeCard,
        zenmodeHistory: newZenmodeHistory,
      };

      break;
    }
    case DealsActionTypes.ToggleZenmode: {
      const isZenmodeOn = action.payload;

      if (!isZenmodeOn) {
        state = {
          ...state,
          isZenmodeOn,
          selectedByZenmodeCard: null,
          viewedCards: [],
          skippedCards: [],
          backtrackedCards: [],
          zenmodeHistory: [],
          untouchedCards: [],
        };
        break;
      }

      state = {
        ...state,
        isZenmodeOn,
      };
      break;
    }

    case DealsActionTypes.UpdateLastWhatsappMessage: {
      const { crmCardId, lastWhatsappMessage } = action.payload;

      const [newList] = updateLastWhatsappMessageInDealsList(state.list, crmCardId, lastWhatsappMessage);

      const newAsideChatList = updateLastWhatsappMessageInAsideChatList(
        state.asideChatList,
        crmCardId,
        lastWhatsappMessage,
      );

      state = {
        ...state,
        list: newList,
        asideChatList: newAsideChatList,
      };
      break;
    }
    case DealsActionTypes.CombinedDealsLoaded: {
      state = {
        ...state,
        zenmodeList: processDeals(
          action.payload.deals,
          action.payload.postSaleDeals,
          state.shadowNextTasksMap,
        ),
      };
      break;
    }

    case DealsActionTypes.GenerateZenmodeList: {
      state = {
        ...state,
        zenmodeList: processDeals(state.list, state.listPostSale, state.shadowNextTasksMap),
      };
      break;
    }
    case DealsActionTypes.AddViewedCard: {
      const isCardAlreadyViewed = state.viewedCards.some(card => card.cardId === action.payload.cardId);
      const isAlreadyInHistory = state.zenmodeHistory.some(card => card.cardId === action.payload.cardId);

      if (!isCardAlreadyViewed && !isAlreadyInHistory) {
        const updatedViewedCards = [...state.viewedCards, action.payload];
        const updatedZenmodeHistory = [...state.zenmodeHistory, action.payload];

        state = {
          ...state,
          viewedCards: updatedViewedCards,
          zenmodeHistory: updatedZenmodeHistory,
        };
      }

      break;
    }

    case DealsActionTypes.AddSkippedCard: {
      const isCardAlreadySkipped = state.skippedCards.some(card => card.cardId === action.payload.cardId);

      if (!isCardAlreadySkipped) {
        const updatedSkippedCards = [...state.skippedCards, action.payload];
        state = {
          ...state,
          skippedCards: updatedSkippedCards,
        };
      }

      break;
    }
    case DealsActionTypes.RemoveSkippedCard: {
      const updatedSkippedCards = state.skippedCards.filter(
        skippedCard => skippedCard.cardId !== action.payload.cardId,
      );

      state = {
        ...state,
        skippedCards: updatedSkippedCards,
      };
      break;
    }

    case DealsActionTypes.RemoveViewedCard: {
      const updatedViewedCards = state.viewedCards.filter(
        viewedCard => viewedCard.cardId !== action.payload.cardId,
      );

      state = {
        ...state,
        viewedCards: updatedViewedCards,
      };
      break;
    }

    case DealsActionTypes.AddShadowTime: {
      const { dealId, nextTask } = action.payload;
      const updatedShadowNextTasksMap = {
        ...state.shadowNextTasksMap,
        [dealId]: getShadowTaskItem(nextTask),
      };

      state = {
        ...state,
        shadowNextTasksMap: updatedShadowNextTasksMap,
      };
      break;
    }

    case DealsActionTypes.DealSelectedByZenmodeCard: {
      const { cardId, dealId, isTouched, status, name } = action.payload;
      const updatedSelectedByZenmodeCard: SelectedZenmodeCard = {
        cardId,
        dealId,
        isTouched,
        status,
        name,
      };

      state = {
        ...state,
        selectedByZenmodeCard: updatedSelectedByZenmodeCard,
      };
      break;
    }

    case DealsActionTypes.SelectedZenmodeCardTouched: {
      const { cardId, dealId } = action.payload;

      const updateCards = (cards: SelectedZenmodeCard[]) =>
        cards.map(card =>
          card.cardId === cardId && card.dealId === dealId ? { ...card, isTouched: true } : card,
        );

      state = {
        ...state,
        selectedByZenmodeCard: {
          ...state.selectedByZenmodeCard,
          isTouched: true,
        },
        skippedCards: updateCards(state.skippedCards),
        viewedCards: updateCards(state.viewedCards),
      };
      break;
    }

    case DealsActionTypes.UpdateUntouchedCards: {
      const newUntouchedCards = getUntouchedCards(state.zenmodeList, state.viewedCards, state.skippedCards);
      state = {
        ...state,
        untouchedCards: newUntouchedCards,
      };
      break;
    }

    case DealsActionTypes.AddToZenmodeList: {
      const { cardId } = action.payload;
      const newZenmodeList: CrmCardViewItem[] = [...state.zenmodeList];
      const existingCardIndex: number = newZenmodeList.findIndex(item => item.card.id === cardId);

      if (existingCardIndex !== -1) {
        // Карточка уже в списке, обновляем ее и перемещаем в начало
        const updatedCard = updateCard(newZenmodeList[existingCardIndex]);
        newZenmodeList.splice(existingCardIndex, 1); // Удаляем карточку с текущей позиции
        newZenmodeList.unshift(updatedCard); // Добавляем в начало списка
      } else {
        // Поиск и добавление новой карточки
        const newCard = findNewCard(state, cardId);
        if (newCard) {
          newZenmodeList.unshift(newCard); // Добавляем новую карточку в начало списка
        }
      }

      state = {
        ...state,
        zenmodeList: newZenmodeList,
      };
      break;
    }

    case DealsActionTypes.RemoveShadowTime: {
      const { cardId } = action.payload;
      const newShadowNextTasksMap = { ...state.shadowNextTasksMap };
      delete newShadowNextTasksMap[cardId];

      state = {
        ...state,
        shadowNextTasksMap: newShadowNextTasksMap,
      };
      break;
    }

    case DealsActionTypes.MarkedAsNotNew: {
      const { dealId } = action.payload;
      const newZenmodeList = state.zenmodeList.map(item =>
        item.deal.id === dealId
          ? {
              ...item,
              deal: {
                ...item.deal,
                conversationStatus: 0,
                isNew: false,
              },
            }
          : item,
      );

      state = {
        ...state,
        zenmodeList: newZenmodeList,
      };
      break;
    }

    default:
      return state;
  }
  return state;
}

function getShadowTaskItem(nextTask: CrmTaskItem): CrmTaskItem {
  const clonedTask: CrmTaskItem = { ...nextTask };
  const currentDate = new Date(); // текущая дата и время

  // Всегда устанавливаем время на 1 час вперед от текущего времени
  const newDate = addHours(currentDate, 1);

  const date = `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, '0')}-${String(
    newDate.getDate(),
  ).padStart(2, '0')}`;
  const time = `${String(newDate.getHours()).padStart(2, '0')}:${String(newDate.getMinutes()).padStart(
    2,
    '0',
  )}:${String(newDate.getSeconds()).padStart(2, '0')}`;

  clonedTask.date = date;
  clonedTask.time = time;

  return clonedTask;
}

function getUntouchedCards(
  zenmodeList: CrmCardViewItem[],
  viewedCards: SelectedZenmodeCard[],
  skippedCards: SelectedZenmodeCard[],
): CrmCardViewItem[] {
  if (!zenmodeList) {
    return [];
  }

  return zenmodeList.filter(card => {
    const isCardViewed = viewedCards.some(viewedCard => viewedCard.cardId === card.card.id);
    const isCardSkipped = skippedCards.some(skippedCard => skippedCard.cardId === card.card.id);

    return !isCardViewed && !isCardSkipped;
  });
}

function updateChatItems(items, payload) {
  return items.map(item =>
    item.dealId === payload.dealId
      ? {
          ...item,
          hasUnreadMessages: payload.conversationStatus === 1,
        }
      : item,
  );
}

function getAsideChatList(payload: CrmCardViewItem[]): AsideChat[] {
  return payload.map((crmCardViewItem: CrmCardViewItem) => {
    return {
      clientAvatar: crmCardViewItem.card.avatar,
      crmId: crmCardViewItem.card.id,
      dealId: crmCardViewItem.deal.id,
      name: crmCardViewItem.card.name,
      phone:
        crmCardViewItem.card.whatsappPhone ||
        crmCardViewItem.card.mobilePhones[0] ||
        crmCardViewItem.card.phones[0],
      hasUnreadMessages: crmCardViewItem.deal.conversationStatus === 1,
      lastMessage: crmCardViewItem.lastWhatsappMessage ? crmCardViewItem.lastWhatsappMessage : null,
    };
  });
}

function mapTourPackagesToUniqueItems(payload: CrmCardListPostSaleItem[]): CrmCardListPostSaleItem[] {
  const tourPackageItemsMap = new Map<number, CrmCardListPostSaleItem>();

  payload.forEach(item => {
    item.tourPackages.forEach(tourPackage => {
      const { id } = tourPackage.tourPackage;
      if (id && !tourPackageItemsMap.has(id)) {
        tourPackageItemsMap.set(id, { ...item, tourPackages: [tourPackage] });
      }
    });
  });

  return Array.from(tourPackageItemsMap.values());
}

function sortTourPackagesByDepartureDate(items: CrmCardListPostSaleItem[]): void {
  items.forEach(item => {
    item.tourPackages.sort((a, b) => {
      const departureA =
        a.tourPackage.flights.length > 0
          ? new Date(a.tourPackage.flights[0].departure.dateTime).getTime()
          : 0;
      const departureB =
        b.tourPackage.flights.length > 0
          ? new Date(b.tourPackage.flights[0].departure.dateTime).getTime()
          : 0;
      return departureA - departureB;
    });
  });
}

function mapPostSaleItemsToAsideChat(items: CrmCardListPostSaleItem[]): AsideChat[] {
  return items.map(item => {
    const crmCardViewItem = item.dealListItem;
    return {
      clientAvatar: crmCardViewItem.card.avatar,
      crmId: crmCardViewItem.card.id,
      dealId: crmCardViewItem.deal.id,
      name: crmCardViewItem.card.name,
      phone:
        crmCardViewItem.card.whatsappPhone ||
        crmCardViewItem.card.mobilePhones[0] ||
        crmCardViewItem.card.phones[0],
      hasUnreadMessages: crmCardViewItem.deal.conversationStatus === 1,
      lastMessage: crmCardViewItem.lastWhatsappMessage ? crmCardViewItem.lastWhatsappMessage : null,
    };
  });
}

function updateLastWhatsappMessageInDealsList(
  dealsList: CrmCardViewItem[],
  crmCardId: number,
  lastWhatsappMessage: WhatsappMessage,
): [CrmCardViewItem[], number] {
  let indexUpdated = -1;
  const updatedList = dealsList.map((crmCardViewItem, index) => {
    if (crmCardViewItem.card.id === crmCardId) {
      indexUpdated = index;
      return {
        ...crmCardViewItem,
        deal: {
          ...crmCardViewItem.deal,
          conversationStatus: 1,
        },
        lastWhatsappMessage,
      };
    }
    return crmCardViewItem;
  });

  return [updatedList, indexUpdated];
}

function updateLastWhatsappMessageInAsideChatList(
  asideChatList: AsideChat[],
  crmCardId: number,
  lastWhatsappMessage: WhatsappMessage,
): AsideChat[] {
  return asideChatList.map(chatItem => {
    if (chatItem.crmId === crmCardId) {
      return {
        ...chatItem,
        lastMessage: lastWhatsappMessage,
      };
    }
    return chatItem;
  });
}

function processDeals(
  deals: CrmCardViewItem[],
  postSaleDeals: CrmCardListPostSaleItem[],
  shadowNextTasksMap: { [key: number]: CrmTaskItem },
): CrmCardViewItem[] {
  const extractAndConvertDealListItems = (postSaleItems: CrmCardListPostSaleItem[]) => {
    return postSaleItems
      .filter((item: CrmCardListPostSaleItem) => item.dealListItem.deal.conversationStatus === 1)
      .map((item: CrmCardListPostSaleItem) => convertToCrmCardViewItem(item.dealListItem));
  };

  const extractedAndConvertedDealListItems = extractAndConvertDealListItems(postSaleDeals);

  const zenmodeOrderInput = {
    value: [...deals, ...extractedAndConvertedDealListItems],
    shadowNextTasksMap,
  };
  return zenmodeOrderPipe.transform(zenmodeOrderInput);
}

function updateCard(card: CrmCardViewItem): CrmCardViewItem {
  return {
    ...card,
    deal: {
      ...card.deal,
      conversationStatus: 1,
      isNew: true,
    },
  };
}

function findNewCard(state, cardId): CrmCardViewItem | undefined {
  const newCard = state.list.find(item => item.card.id === cardId);
  if (!newCard) {
    const postSaleItem = state.listPostSale.find(item => item.dealListItem.card.id === cardId);
    if (postSaleItem) {
      return updateCard(postSaleItem.dealListItem);
    }
  } else {
    return updateCard(newCard);
  }
}
