import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { WhatsappMessage } from '@api-clients/crm-api-client';
import { Observable, of } from 'rxjs';

import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';
import { ResizableTextareaDirective } from '../../../../../shared/directives/resizable-textarea.directive';

@Component({
  selector: 'app-content-creator-custom-message',
  templateUrl: './content-creator-custom-message.component.html',
  styleUrls: ['./content-creator-custom-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [FormsModule, MatInputModule, ResizableTextareaDirective],
})
export class ContentCreatorCustomMessageComponent implements ContentCreatorItemComponent, OnInit {
  @Input() tourId: string;
  @Input() tourContent: TourContent;
  @Input() initialMessage: WhatsappMessage;

  message: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.message = this.initialMessage.text;
  }

  setMessage(message: string): void {
    this.message = message;
    this.cdr.detectChanges();
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    if (!this.message) {
      return of([] as ContentCreatorItemMessage[]);
    }
    return of([
      {
        component: this,
        message: { text: this.message },
      },
    ]);
  }
}
