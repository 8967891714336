<app-close-modal-button [dialogRef]="dialogRef" />
<div class="columns">
  <div class="column">
    <h3>{{ 'PAGES.SETTINGS.BASE' | translate }}</h3>
    <button (click)="openMessageTemplatesModal()" class="btn --green change-tab">
      {{ 'PAGES.SETTINGS.WHATSAPP.TEMPLATES_TITLE' | translate }}
    </button>
    <div class="toggle toggle--with-sub-groups">
      <div class="toggle">
        <span class="toggle-title">{{ 'PAGES.SETTINGS.WHATSAPP.TOGGLE_TITLE' | translate }}</span>
        <app-slide-toggle
          [defaultValue]="isWhatsappNotificationsEnabled"
          (change)="onWhatsappNotificationsSettingChange($event)"
        >
        </app-slide-toggle>
      </div>
      <div class="sub-group" [class.disabled]="!isWhatsappNotificationsEnabled">
        <div class="toggle">
          <span class="toggle-title">{{ 'PAGES.SETTINGS.WHATSAPP_CHATS.TOGGLE_TITLE' | translate }}</span>
          <app-slide-toggle
            [defaultValue]="isWhatsappChatsNotificationsEnabled"
            (change)="saveWhatsappChatsNotificationsEnabled($event)"
          >
          </app-slide-toggle>
        </div>
        <div class="toggle">
          <span class="toggle-title">{{ 'PAGES.SETTINGS.WHATSAPP_GROUPS.TOGGLE_TITLE' | translate }}</span>
          <app-slide-toggle
            [defaultValue]="isWhatsappGroupsNotificationsEnabled"
            (change)="saveWhatsappGroupsNotificationsEnabled($event)"
          >
          </app-slide-toggle>
        </div>
      </div>
    </div>
    <div class="toggle">
      <h4 class="toggle-title">{{ 'PAGES.SETTINGS.ZOIPER_CALL.TOGGLE_TITLE' | translate }}</h4>
      <app-slide-toggle
        [disabledStateText]="labels.ZOIPER_CALL_DISABLED"
        [enabledStateText]="labels.ZOIPER_CALL_ENABLED"
        [defaultValue]="isZoiperCall$ | async"
        (change)="changeIsZoiperCall($event)"
      >
      </app-slide-toggle>
    </div>
    <div class="toggle">
      <h4 class="toggle-title">{{ 'PAGES.SETTINGS.ASIDE_CHATS_SCROLL.TOGGLE_TITLE' | translate }}</h4>
      <app-slide-toggle
        [disabledStateText]="labels.ASIDE_CHATS_SCROLL_DISABLED"
        [enabledStateText]="labels.ASIDE_CHATS_SCROLL_ENABLED"
        [defaultValue]="isAsideChatsScrollEnabled$ | async"
        (change)="changeIsAsideChatScrollEnabled($event)"
      >
      </app-slide-toggle>
    </div>
    <div class="toggle">
      <h4 class="toggle-title">{{ 'PAGES.SETTINGS.SALES_NOTIFY.TOGGLE_TITLE' | translate }}</h4>
      <app-slide-toggle [defaultValue]="salesNotifyDisabled" (change)="changeIsSalesNotifyDisabled($event)">
      </app-slide-toggle>
    </div>
    <div class="zoom-level">
      <span>Текущий масштаб:</span>
      <div class="zoom-level__body">
        <button (click)="zoomOut()" class="btn --small">-</button>
        <span>{{ zoomLevel() | zoomLevelToPercent }}</span>
        <button (click)="zoomIn()" class="btn --small">+</button>
      </div>
    </div>
  </div>
  <div class="column">
    <h3>{{ 'PAGES.SETTINGS.EMPLOYEE.TITLE' | translate }}</h3>
    <button (click)="openUpdateScreen()" class="btn --with-outline open-dev-tools">
      Открыть окно обновления программы
    </button>
    <dl>
      <dt>{{ 'PAGES.SETTINGS.EMPLOYEE.LOGIN' | translate }}</dt>
      <dd>{{ worker.username }}</dd>
      <dt>{{ 'PAGES.SETTINGS.EMPLOYEE.INTERNAL_NUMBER' | translate }}</dt>
      <dd>{{ worker.sip.innerPhone }}</dd>
      <dt>{{ 'PAGES.SETTINGS.EMPLOYEE.FULL_NAME' | translate }}</dt>
      <dd>{{ worker.name }}</dd>
    </dl>
  </div>
  <div class="column">
    <h3>Настройки разработчика</h3>
    <button (click)="changeToDevVersion()" class="btn --with-outline open-dev-tools">
      Включить dev версию
    </button>
    @if (isShowDevToolsColumn) {
      <app-zenmode-toggle />
      <button (click)="openZenmodeList()" class="btn --with-outline open-dev-tools">
        Zenmode beta (список)
      </button>
      <button (click)="openDevTools()" class="btn --with-outline open-dev-tools">
        {{ 'PAGES.SETTINGS.OPEN_DEV_TOOLS' | translate }}
      </button>
      <button (click)="openNotificationsDevTools()" class="btn --with-outline open-dev-tools">
        {{ 'PAGES.SETTINGS.OPEN_DEV_TOOLS_NOTIFICATIONS' | translate }}
      </button>
      <button (click)="sendFakeNotifyAboutTourPackageDocs()" class="btn --with-outline open-dev-tools">
        Отправить тестовое уведомление Документы готовы
      </button>
      <button (click)="sendFakeNotifyAboutCall()" class="btn --with-outline open-dev-tools">
        Отправить тестовое уведомление о звонке
      </button>
      <button (click)="sendFakeNotifyAboutCallFromApp()" class="btn --with-outline open-dev-tools">
        Отправить тестовое уведомление о звонке c приложения
      </button>
      <button (click)="sendFakeNotifyAboutCallWithoutDealView()" class="btn --with-outline open-dev-tools">
        Отправить тестовое уведомление о звонке (без данных)
      </button>
      <button (click)="showInConsoleNotificationsWindowInfo()" class="btn --with-outline open-dev-tools">
        Показать в консоли информацию об окне уведомлений
      </button>
      <button (click)="sendFakeOnStartConversation()" class="btn --with-outline open-dev-tools">
        С эмулировать поднятие трубки
      </button>
      <button (click)="openTestUrl()" class="btn --with-outline open-dev-tools">
        Открыть тестовую страницу в браузере (nodejs)
      </button>
      <button (click)="openTestUrlElectron()" class="btn --with-outline open-dev-tools">
        Открыть тестовую страницу в браузере (electron)
      </button>
    } @else {
      <button (click)="isShowDevToolsColumn = true" class="btn --with-outline open-dev-tools">
        {{ 'PAGES.SETTINGS.SHOW_DEV_TOOLS_COLUMN' | translate }}
      </button>
    }
  </div>
</div>
