import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Notification, NotificationType } from '@api-clients/crm-api-client/dist/models';
import { TranslateModule } from '@ngx-translate/core';
import { TimeSincePipe } from '../../pipes/time-since.pipe';
import { DealAssignedComponent } from './components/deal-assigned/deal-assigned.component';
import { TourPackageClaimConfirmedComponent } from './components/tour-package-claim-confirmed/tour-package-claim-confirmed.component';
import { TourPackageClaimOutdatedComponent } from './components/tour-package-claim-outdated/tour-package-claim-outdated.component';
import { TourPackageDocsListChangedComponent } from './components/tour-package-docs-list-changed/tour-package-docs-list-changed.component';
import { TourPackageDocsReadyComponent } from './components/tour-package-docs-ready/tour-package-docs-ready.component';
import { TourPackageNewPaymentComponent } from './components/tour-package-new-payment/tour-package-new-payment.component';

@Component({
  selector: 'app-notify-center-item',
  templateUrl: './notify-center-item.component.html',
  styleUrls: ['./notify-center-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [
    NgClass,
    TranslateModule,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    DealAssignedComponent,
    TourPackageClaimConfirmedComponent,
    TourPackageDocsListChangedComponent,
    TourPackageDocsReadyComponent,
    TourPackageNewPaymentComponent,
    TourPackageClaimOutdatedComponent,
    TimeSincePipe,
  ],
})
export class NotifyCenterItemComponent implements OnInit {
  @Input() notification: Notification;
  @Output() deletedById: EventEmitter<number> = new EventEmitter<number>();
  public NotificationType = NotificationType;
  public isHovered: boolean;

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  openDealView(crmCardId: number) {
    this.router.navigate([`/deals`]).then(() => this.router.navigate([`/deals/view/${crmCardId}`]).then());
  }

  showDeleteButton() {
    this.isHovered = true;
    this.cdRef.detectChanges();
  }

  hideDeleteButton() {
    this.isHovered = false;
    this.cdRef.detectChanges();
  }

  deleteById(id: number) {
    this.deletedById.emit(id);
  }
}
