import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsCenterListComponent } from '../components/notifications-center-list/notifications-center-list.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationsCenterPopupService {
  constructor(public matDialog: MatDialog) {}

  showPopup(): void {
    this.matDialog.open(NotificationsCenterListComponent, {
      id: 'notifications-center-popup',
      width: '400px',
      hasBackdrop: true,
      minHeight: '300px',
      position: {
        right: '10px',
        top: '35px',
      },
      panelClass: 'notifications-center-popup',
    });
  }
}
