import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { ChatApiService } from '../../core/services/chat/chat-api.service';
import { MessageSyncService } from '../../modules/chat-timeline/services/message-sync.service';
import {
  ChatMessagesActionTypes,
  DeleteMessage,
  DeleteMessageFailure,
  DeleteMessageSuccess,
} from './chat-messages.actions';

@Injectable()
export class ChatMessagesEffects {
  deleteMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteMessage>(ChatMessagesActionTypes.DeleteMessage),
      switchMap(({ messageId }) =>
        this.chatApiService.deleteMessage({ messageId }).pipe(
          tap(() => {
            this.messageSyncService.notifyMessageDeleted(messageId);
          }),
          map(() => new DeleteMessageSuccess(messageId)),
          catchError(error => of(new DeleteMessageFailure(error))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private chatApiService: ChatApiService,
    private messageSyncService: MessageSyncService,
  ) {}
}
