import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TourPackagePaymentNotification } from '@api-clients/crm-api-client/dist/models/tour-package-payment-notification';
import { TranslateModule } from '@ngx-translate/core';
import { ExternalLinkDirective } from '../../../../../../shared/directives/external-link.directive';

@Component({
  selector: 'app-tour-package-new-payment',
  templateUrl: './tour-package-new-payment.component.html',
  styleUrls: ['./tour-package-new-payment.component.scss'],

  imports: [TranslateModule, DecimalPipe, ExternalLinkDirective],
})
export class TourPackageNewPaymentComponent implements OnInit {
  @Input() notification: TourPackagePaymentNotification;

  ngOnInit(): void {}
}
