import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, timer } from 'rxjs';
import { delay, retryWhen, share, switchMap, takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../../../environments/environment';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { apiResponsePipe } from '../../../api-response.pipe';

const INSTANCE_REFRESH_INTERVAL = 120000;

@Injectable({
  providedIn: 'root',
})
export class ContactsService implements OnDestroy {
  private stopPollingSubject = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  getContacts() {
    return this.http.get<ApiResponse<any>>(`${AppConfig.apiUrl}/whatsapp/get-contacts`).pipe(apiResponsePipe);
  }

  getInstance() {
    return timer(1, INSTANCE_REFRESH_INTERVAL).pipe(
      switchMap(() =>
        this.http.get<ApiResponse<any>>(`${AppConfig.apiUrl}/whatsapp/get-my-instance`).pipe(apiResponsePipe),
      ),
      retryWhen(errors => errors.pipe(delay(INSTANCE_REFRESH_INTERVAL))),
      share(),
      takeUntil(this.stopPollingSubject),
    );
  }

  ngOnDestroy() {
    this.stopPollingSubject.next(true);
  }
}
