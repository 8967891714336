import { Component, ChangeDetectionStrategy, OnInit, signal, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgClickOutsideDirective, NgClickOutsideExcludeDirective } from 'ng-click-outside2';
import { BrxInputSearchComponent } from '../../../../ui-components/hermes/input-search/brx-input-search.component';
import { PreloaderComponent } from '../../../../ui-components/preloader/preloader.component';
import { SearchService } from './services/search.service';
import { Notify, NotifyService, NotifyTypeEnum } from '../../../../shared/notify/services/notify.service';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { MAIN_SEARCH_USE } from '../../../../core/services/amplitude/amplitudeEvents';
import { SearchHistoryService } from './services/search-history.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClickOutsideDirective,
    TranslateModule,
    PreloaderComponent,
    BrxInputSearchComponent,
    NgClickOutsideExcludeDirective,
    FormsModule,
  ],
})
export class SearchFormComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  public isLoading = signal(false);
  public isHelperVisible = signal(false);
  public searchHistory: string[] = [];
  public readonly screenType: ScreenTypes = ScreenTypes.TOP_MENU;
  public searchInputValue = signal('');

  constructor(
    private searchService: SearchService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotifyService,
    private amplitudeTrackService: AmplitudeTrackService,
    private searchHistoryService: SearchHistoryService,
  ) {
    this.searchHistory = this.searchHistoryService.getSearchHistory();
  }

  ngOnInit(): void {
    const term = this.termQueryValue;
    if (term) {
      this.searchBySlug(term);
    }

    this.searchService.clearSearchInputValue$
      .asObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.searchInputValue.set(''));
  }

  searchBySlug(term?: string): void {
    this.isLoading.set(true);
    this.isHelperVisible.set(false);
    if (term) {
      this.searchInputValue.set(term);
    }
    const searchInputValue = this.searchInputValue();
    this.amplitudeTrackService.trackEvent(MAIN_SEARCH_USE, {
      term: searchInputValue,
      screenType: this.screenType,
    });
    if (!searchInputValue.length) {
      this.isLoading.set(false);
      const notify: Notify = {
        title: 'Поиск клиента',
        text: 'Строка поиска пустая, пожалуйста, напишите туда что-нибудь =)',
        type: NotifyTypeEnum.error,
      };
      this.notifyService.create(notify);
      return;
    }
    this.searchService
      .searchBySlug(searchInputValue.trim())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(results => {
        this.searchHistoryService.saveSearchQuery(searchInputValue);
        this.searchService.searchResults$.next({ data: results?.list, term: searchInputValue });
        this.router.navigate(['search-results']).then();
        this.isLoading.set(false);
      });
  }

  get termQueryValue(): string {
    return this.activatedRoute.snapshot.queryParamMap.get('term');
  }

  showHelper() {
    this.searchHistory = this.searchHistoryService.getSearchHistory();
    this.isHelperVisible.set(true);
  }
}
