import { AsyncPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { TranslatePipe } from '@ngx-translate/core';
import { BehaviorSubject, from, Observable, of, combineLatest, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { WindowMessagesService, WorkerStateService } from '../../../../../core/services';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../ui-components/alert-label/alert-label.component';

import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';
import { PreloaderComponent } from '../../../../../ui-components/preloader/preloader.component';

@Component({
  selector: 'app-prices-calendar',
  templateUrl: './prices-calendar.component.html',
  styleUrls: ['./prices-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [PreloaderComponent, AsyncPipe, AlertLabelComponent],
})
export class PricesCalendarComponent implements OnInit, ContentCreatorItemComponent, OnDestroy {
  @Input() public tourId: string;
  @Input() public tourContent: TourContent;

  public calendarUrl: string;
  public changedCalendarUrl: string;
  public htmlCalendarUrl: SafeResourceUrl;

  public isLoading$ = new BehaviorSubject(true);
  public errorMessage$ = new BehaviorSubject<string | null>(null);
  private destroy$ = new Subject<void>();

  public isCalendarFrameVisible$ = combineLatest([this.isLoading$, this.errorMessage$]).pipe(
    map(([isLoading, errorMessage]) => !isLoading && errorMessage === null),
  );

  @ViewChild('frame', { read: ViewContainerRef, static: false }) frame: ViewContainerRef;

  protected readonly dangerAlertType = AlertLabelType.danger;

  constructor(
    private workerStateService: WorkerStateService,
    private sanitizer: DomSanitizer,
    private windowMessagesService: WindowMessagesService,
    private translatePipe: TranslatePipe,
    private httpClient: HttpClient,
  ) {}

  ngOnInit(): void {
    this.calendarUrl =
      `https://ht.kz/tours/tour/pricesCalendarScreenshot?tourId=${this.tourId}` +
      `&onlyRealTimePrices=1&minimumDatesToGenerate=1` +
      `&brandId=${this.workerStateService.currentWorkerValue.brandId}`;
    // При изменении URL фрейма с календарем, обновляем его.
    // Когда менеджер будет кликать по датам он будет меняться
    this.windowMessagesService.messages$
      .pipe(
        takeUntil(this.destroy$),
        filter(message => message?.place === 'prices-calendar-screenshot'),
      )
      .subscribe(message => {
        this.isLoading$.next(false);
        if (message.error) {
          this.errorMessage$.next(
            this.translatePipe.transform('PAGES.CONTENT_CREATOR.CONTENT_ITEMS.PRICES_CALENDAR.NOT_FOUND'),
          );
          return;
        }
        this.changedCalendarUrl = message.href;
      });
    this.htmlCalendarUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${this.calendarUrl}&format=html&allowChangeDateAndNight=1&width=200`,
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Загружает содержимое файла по URL для дальнейшей отправки
   * @param name
   * @param url
   * @private
   */
  private getFileFromUrl(name: string, url: string): Observable<File> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      switchMap(blob => {
        return from(Promise.resolve(new File([blob], name, { type: blob.type })));
      }),
    );
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    if (this.errorMessage$.value) {
      return of([] as ContentCreatorItemMessage[]);
    }

    // Надо удалить из URL ненужные параметры, которые используются для предпросмотра
    const url = new URL(this.changedCalendarUrl);
    const params = new URLSearchParams(url.search);
    params.delete('allowChangeDateAndNight');
    params.delete('format');
    params.delete('width');
    url.search = params.toString();

    return this.getFileFromUrl('prices-calendar.png', url.toString()).pipe(
      map(file => {
        return [
          {
            component: this,
            message: { fileContent: file },
          },
        ];
      }),
    );
  }
}
