import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zoomLevelToPercent',
})
export class ZoomLevelToPercentPipe implements PipeTransform {
  transform(zoomLevel: number): string {
    const zoom = Math.round(zoomLevel * 100);
    return `${zoom}%`;
  }
}
