import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { AmplitudeTrackService } from '../../core/services/amplitude/amplitude-track.service';
import {
  DELAYED_MESSAGE_DELETED,
  DELAYED_MESSAGE_PLANNED,
  DELAYED_MESSAGE_RESCHEDULED,
} from '../../core/services/amplitude/amplitudeEvents';
import { WhatsappScheduleMessagesService } from '../../shared/services/whatsapp-scheduled-messages.service';
import * as ChatTimelineActions from './chat-timeline.actions';

@Injectable()
export class ChatTimelineEffects {
  // Эффект для загрузки списка запланированных сообщений
  $loadScheduledMessages = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatTimelineActions.loadScheduledMessages),
      switchMap(({ request }) =>
        this.whatsappScheduleMessagesService.getScheduledMessages(request).pipe(
          map(messages => ChatTimelineActions.loadScheduledMessagesSuccess({ messages })),
          catchError(error => of(ChatTimelineActions.loadScheduledMessagesFailure({ error }))),
        ),
      ),
    ),
  );

  // Эффект для создания нового запланированного сообщения
  createScheduledMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatTimelineActions.createScheduledMessage),
      switchMap(({ request }) =>
        this.whatsappScheduleMessagesService.createScheduledMessage(request).pipe(
          map(() => ChatTimelineActions.createScheduledMessageSuccess({ request })),
          catchError(error => of(ChatTimelineActions.createScheduledMessageFailure({ error }))),
        ),
      ),
    ),
  );

  // Эффект успешного создания запланированного сообщения
  createScheduledMessageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatTimelineActions.createScheduledMessageSuccess),
      tap(({ request }) => {
        this.amplitudeTrackService.trackEvent(DELAYED_MESSAGE_PLANNED, {
          'Message text': request.text,
        });
      }),
      switchMap(({ request }) => {
        const loadRequest = {
          phone: request.phone,
        };
        return of(ChatTimelineActions.loadScheduledMessages({ request: loadRequest }));
      }),
    ),
  );

  // Эффект для изменения запланированного сообщения
  rescheduleMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatTimelineActions.rescheduleMessage),
      switchMap(({ request }) =>
        this.whatsappScheduleMessagesService.rescheduleMessage(request).pipe(
          map(() => ChatTimelineActions.rescheduleMessageSuccess()),
          catchError(error => of(ChatTimelineActions.rescheduleMessageFailure({ error }))),
        ),
      ),
    ),
  );

  // Эффект успешного изменения запланированного сообщения
  rescheduleMessageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatTimelineActions.rescheduleMessageSuccess),
        tap(() => {
          this.amplitudeTrackService.trackEvent(DELAYED_MESSAGE_RESCHEDULED);
        }),
      ),
    { dispatch: false },
  );

  // Эффект для удаления запланированного сообщения
  deleteScheduledMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatTimelineActions.deleteScheduledMessage),
      switchMap(({ request }) =>
        this.whatsappScheduleMessagesService.deleteScheduledMessage(request).pipe(
          map(() =>
            ChatTimelineActions.deleteScheduledMessageSuccess({
              messageId: request.id,
            }),
          ),
          catchError(error => of(ChatTimelineActions.deleteScheduledMessageFailure({ error }))),
        ),
      ),
    ),
  );

  // Эффект успешного удаления запланированного сообщения
  deleteScheduledMessageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatTimelineActions.deleteScheduledMessageSuccess),
        tap(() => {
          this.amplitudeTrackService.trackEvent(DELAYED_MESSAGE_DELETED);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private whatsappScheduleMessagesService: WhatsappScheduleMessagesService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}
}
